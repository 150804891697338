import { createClient } from "@guidewire/ui-integration-sdk";
import { getCarrier, getUser } from "./AuthService";

class GuidewireApiService {

  //getGwClient method makes sure that guidewire client is only created once
  //as per guidewire the return should be saved and not created again to avoid timeout issues  
  static getGwClient() {
    if (GuidewireApiService.gwClient) {
      return Promise.resolve(GuidewireApiService.gwClient);
    }

    return getUser().then(function (user) {
      //Module entry point, takes the configuration for the client that will be created.
      //Returns a Promise that resolves to the initialized client.
      GuidewireApiService.gwClient = createClient("ontellus", "records", user.access_token);
      return Promise.resolve(GuidewireApiService.gwClient);
    });
  }

  static showRelatedTo() {
    return true;
  }

  static isSessionActive() {
    // Temp logic to invalidate the page after X minutes from first open!
    if (!window.ontellus_open_date) {
      window.ontellus_open_date = Date(Date.now());
    }

    const sessionLifeInMinutes = 60;
    const sessionExpireDatetime = new Date(window.ontellus_open_date).getTime() + (sessionLifeInMinutes * 60000);
    const isSessionActive = Date.now() < sessionExpireDatetime;
    return isSessionActive;
  }

  static getRelatedToInfo(candidateId) {
    return this.getGwClient().then(function (gwClient) {
      return gwClient.getClient().then(function (client) {
        return client.httpRequest("GET", "/serviceables/" + candidateId);
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        const carrier = getCarrier();

        if (carrier && carrier.PopulateClaimant && data.claimant) {
          return {
            claimNumber: data.claimNumber,
            ...data.claimant
          }
        } else {
          return {
            claimNumber: data.claimNumber,
            ...data.primaryContact
          }
        }
      })
    })
  }

  static getCaseStatus(caseNo) {
    return this.getGwClient().then(function (gwClient) {
      return gwClient.getClient().then(function (client) {
        return client;
      }).then(function (client) {
        return client.httpRequest("GET", "/servicerequest/" + caseNo + "/status");
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        return data;
      })
    })
  }

  static getRelatedTo() {
    return this.getGwClient().then(function (gwClient) {
      return gwClient.getClient().then(function (client) {
        return Promise.all([client, client.getContext()]);
      }).then(function (values) {
        return values[1].candidates;
      });
    });
  }

  static createCase(orderId, serviceableId) {
    return this.getGwClient().then(function (gwClient) {

      var refNumber = orderId;
      var jobId = orderId;

      var serviceRequest = {
        "referenceNumber": refNumber,
        "serviceableId": serviceableId,
        "referenceId1": jobId
      }
      return gwClient.getClient().then(function (client) {
        return Promise.all([client, client.invokeWithoutRefresh("createService", serviceRequest)])
      })
    })
      .then(function (values) {
        if (!values[1].referenceNumber) {
          throw new Error(`Expected a referenceNumber but received this instead ${JSON.stringify(values[1])}`)
        }
        values[0].navigate("servicerequest", values[1].referenceNumber);
      });
  }
}

export default GuidewireApiService;