import React from 'react';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MySelect from '../../Shared/MySelect/MySelect';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import green from '@mui/material/colors/green';
import { Grid } from '@mui/material';
import { NumberCustomeFormat } from '../../Core/CommonComponents';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formWrapper: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit
  },
  alignRight: {
    float: "right"
  }
});

const CreateLocationModal = (props) => {
  const { initialData, onCancel, onAddLocation, isEditMode, states } = props;
  const classes = props.classes;

  return (
    <div className={classes.root}>
      <div className={classes.formWrapper}>
        <Formik
          initialValues={initialData}
          enableReinitialize={true}

          onSubmit={(values, { setSubmitting }) => {
            onAddLocation(values);
            setSubmitting(false);
          }}

          validationSchema={
            Yup.object().shape({
              locationName: Yup
                .string()
                .strict(false)
                .trim()
                .min(1, "Please enter at least 1 character up to a maximum of 160")
                .max(160, "Please enter at least 1 character up to a maximum of 160")
                .required('Required'),
              locationAddress: Yup
                .string()
                .strict(false)
                .trim()
                .min(1, "Please enter at least 1 character up to a maximum of 120")
                .max(120, "Please enter at least 1 character up to a maximum of 120")
                .required('Required'),
              locationCity: Yup
                .string()
                .strict(false)
                .trim()
                .min(1, "Please enter at least 1 character up to a maximum of 40")
                .max(40, "Please enter at least 1 character up to a maximum of 40")
                .required('Required'),
              locationState: Yup
                .mixed()
                .required('Required'),
              locationZip: Yup
                .string()
                .strict(false)
                .trim()
                .min(5, "Please enter 5 digits")
                .max(5, "Please enter 5 digits")
                .required('Required'),
              locationPhone: Yup
                .string()
                .strict(false)
                .trim()
                .min(10, "Please enter 10 digits") // We must require 12 characters, but tell the user 10 digits because the dashes are included
                .max(10, "Please enter 10 digits") // ex: 123-456-7890 has 12 characters, but the user only enters the 10 digits
                .required('Required'),
              locationFax: Yup
                .string()
                .strict(false)
                .trim()
                .min(10, "Please enter 10 digits") // Same for fax.
                .max(10, "Please enter 10 digits")
            })
          }
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <TextField
                    variant="standard"
                    error={errors.locationName && touched.locationName}
                    helperText={(errors.locationName && touched.locationName) && errors.locationName}
                    id="locationName"
                    name="locationName"
                    label="Facility"
                    className={classes.textField}
                    fullWidth
                    multiline
                    required
                    placeholder="Enter Facility Name"
                    value={values.locationName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 160
                    }}
                  />
                </FormControl>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <TextField
                    variant="standard"
                    error={errors.locationAddress && touched.locationAddress}
                    helperText={(errors.locationAddress && touched.locationAddress) && errors.locationAddress}
                    id="locationAddress"
                    name="locationAddress"
                    label="Street Address"
                    placeholder="Enter Street Address"
                    className={classes.textField}
                    multiline
                    required
                    value={values.locationAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 120
                    }}
                  />
                </FormControl>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <TextField
                    variant="standard"
                    error={errors.locationCity && touched.locationCity}
                    helperText={(errors.locationCity && touched.locationCity) && errors.locationCity}
                    id="locationCity"
                    name="locationCity"
                    label="City"
                    placeholder="Enter City"
                    className={classes.textField}
                    required
                    value={values.locationCity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 40
                    }}
                  />
                </FormControl>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <MySelect
                    error={errors.locationState && touched.locationState}
                    helperText={(errors.locationState && touched.locationState) && errors.locationState}
                    id="locationState"
                    name="locationState"
                    label="State *"
                    placeholder=""
                    required
                    value={values.locationState}
                    options={states}
                    handleChange={state => setFieldValue('locationState', state)}
                    isLoading={false}
                  />
                </FormControl>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <TextField
                    variant="standard"
                    error={errors.locationZip && touched.locationZip}
                    helperText={(errors.locationZip && touched.locationZip) && errors.locationZip}
                    id="locationZip"
                    name="locationZip"
                    label="Zip Code"
                    placeholder="Enter Zip Code"
                    className={classes.textField}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.locationZip}
                    InputProps={{
                      inputComponent: NumberCustomeFormat,
                      inputProps: {
                        format: '#####'
                      }
                    }}
                  />
                </FormControl>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <TextField
                    variant="standard"
                    error={errors.locationPhone && touched.locationPhone}
                    helperText={(errors.locationPhone && touched.locationPhone) && errors.locationPhone}
                    id="locationPhone"
                    name="locationPhone"
                    label="Phone Number"
                    placeholder="xxx-xxx-xxxx"
                    className={classes.textField}
                    required
                    onChange={handleChange}
                    value={values.locationPhone}
                    onBlur={handleBlur}
                    InputProps={{
                      inputComponent: NumberCustomeFormat,
                      inputProps: {
                        format: '###-###-####'
                      }
                    }}
                  />
                </FormControl>
                <FormControl className={classes.formControl} fullWidth margin="normal">
                  <TextField
                    variant="standard"
                    error={errors.locationFax && touched.locationFax}
                    helperText={(errors.locationFax && touched.locationFax) && errors.locationFax}
                    id="locationFax"
                    name="locationFax"
                    label="Fax Number"
                    placeholder="xxx-xxx-xxxx"
                    className={classes.textField}
                    onChange={handleChange}
                    value={values.locationFax}
                    onBlur={handleBlur}
                    InputProps={{
                      inputComponent: NumberCustomeFormat,
                      inputProps: {
                        format: '###-###-####'
                      }
                    }}
                  />
                </FormControl>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color='secondary'
                      className={classes.button}
                      disabled={isSubmitting}
                      onClick={onCancel}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color="primary" type="submit" className={classes.button} variant="contained" disabled={isSubmitting} >
                      {isEditMode ? 'Save' : "Add"}
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
export default withStyles(styles)(CreateLocationModal);