import axios from "axios";

const settings = window.APP_SETTINGS;

export const getApplicationFeatures = async () => {
  return await axios({
    method: "GET",
    url: `${settings.MYO_API_BASE_URL}/features?prefix=${settings.APP_NAME_KEY}`,
  });
};
