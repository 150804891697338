import { Fab } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { getClientManager } from '../../Services/MyoApiService';

const styles = theme => ({
  cardClass: {
    textAlign: 'left',
    fontSize: '16px'
  },
  clickToEmail: {
    marginRight: '5px'
  },
  fabButton: {
    color: '#fff !important',
    boxShadow: 'none !important',
    border: 'none !important',
    padding: '0px !important',
    height: '20px !important',
    background: 'transparent !important',
    textTransform: 'capitalize !important',
    "&:hover": {
      background: 'transparent !important',
      textDecoration: 'underline !important'
    }
  }
});


const ClientManagerEmailLink = (props) => {
  const { subject } = props;
  const classes = props.classes;

  const [clientManager, setClientManager] = useState({});
  useEffect(() => {
    const fetchClientManager = async () => {
      const clientManager = await getClientManager();
      setClientManager(clientManager);
    }
    fetchClientManager();
  }, []);
  return (
    clientManager.name ? <div className={classes.cardClass}>
      Your Client Manager:  {clientManager.name}
      <div>
        <Fab variant="extended"  className={classes.fabButton} onClick={(e) => {
                window.location = `mailto:${clientManager.email}?Subject=${subject ? subject : ''}`;
                e.preventDefault();
            }}>
          <MailOutlineIcon  className={classes.clickToEmail}  />
          Click to Email
        </Fab>
      </div>
    </div> : ""
  );
}

export default withStyles(styles)(ClientManagerEmailLink);