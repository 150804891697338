import { BlockBlobClient } from "@azure/storage-blob";
import axios from "axios";
import { getApplicationFeatures } from "./FeatureService";
const settings = window.APP_SETTINGS;

const getUploadUrl = async (fileName, totalFileSize, canceltoken) => {
  return await axios({
    method: "POST",
    url: `${settings.MYO_API_BASE_URL}/files/generatefileuploadtoken`,
    data: {
      fileName,
      totalFileSize,
    },
    cancelToken: canceltoken,
  });
};

const uploadFileToBlob = async (file, url, controller, progress) => {
  try {
    const blockBlobClient = new BlockBlobClient(url);
    return await blockBlobClient.uploadData(file, { abortSignal: controller.signal });
  } catch (error) {
    console.error(error.message);
    console.error(error);
    return Promise.reject();
  }
};

const uploadFileUsingBlob = async (
  file,
  cancelTokenSource,
  controller,
  progress
) => {
  const url = await getUploadUrl(file.name, file.size, cancelTokenSource.token);

  await uploadFileToBlob(file, url.data, controller, progress);
  return url;
};
const upload = (formData, progress, cancelTokenSource) => {
  return axios({
    method: "POST",
    url: `${settings.MYO_API_BASE_URL}/files`,
    data: formData,
    cancelToken: cancelTokenSource.token,
    onUploadProgress: (e) => {
      // updating progress indicator
      progress(e.lengthComputable, e.loaded, e.total);
    },
  });
};

const getFeatures = async () => {
  try {
    return await getApplicationFeatures();
  } catch (error) {
    return { data: {} };
  }
};

export const uploadFile = async (
  file,
  cancelTokenSource,
  controller,
  progress,
  formData
) => {
  let features = await getFeatures();

  let useBlob = false;
  if (features && features.data)
    useBlob = features.data.IntegratedForm_IsBlobFileUploadEnabled;

  if (useBlob)
    return await uploadFileUsingBlob(
      file,
      cancelTokenSource,
      controller,
      progress
    );
  else {
    return await upload(formData, progress, cancelTokenSource);
  }
};
