import React from "react";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import { withStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const styles = theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${
      theme.spacing.unit
    }px ${theme.spacing.unit * 2}px`,
    marginTop: `${theme.spacing.unit}px`
  },
  avatar: {
    backgroundColor: theme.palette.primary.main
  }
});

function Banner(props) {
  const classes = props.classes;
  const { message } = props;
  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.paper}>
        <Grid container wrap="nowrap" spacing={16} alignItems="center">
          <Grid item>
            <Avatar className={classes.avatar}>
              <ErrorOutlineIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography color="error">
              {message}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="flex-end" spacing={8}>
          <Grid item>
            <Button color="primary">Turn on wifi</Button>
          </Grid>
        </Grid> */}
      </Paper>
      <Divider />
      <CssBaseline />
    </React.Fragment>
  );
}

export default withStyles(styles)(Banner);