import React, { useState } from 'react';
import { styled, withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MySelect from '../Shared/MySelect/MySelect';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import green from '@mui/material/colors/green';
import {
    Card, CardContent, Checkbox, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControlLabel, FormLabel, Grid, IconButton, Tooltip
} from '@mui/material';
import { FormikDatePicker } from '../Shared/FormikDatePicker';
import DateFnsUtils from '@date-io/date-fns';
import CreateLocationModal from './CreateLocation/CreateLocation';
import { fetchSelectedLocation, locationSearch } from '../../Services/MyoApiService';
import { LocalizationProvider } from '@mui/lab';
import { Edit } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formWrapper: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit
    },
    alignRight: {
        float: "right"
    },
    locationDetailheader: {
        display: 'inherit'
    },
    locationPhone: {
        maxWidth: 'none'
    },
    locationName: {
        color: 'rgba(0, 0, 0, 0.87)',
        paddingBottom: theme.spacing.unit
    },
    buttonLocation: {
        textTransform: 'none',
        position: 'absolute',
    },
    card: {
        width: '100%',
        marginBottom: '20px',
        overflow: 'visible !important'
    }
});


/*** End Record Type functions  ***/

const LocationInformationModal = (props) => {
    const { initialData, onCancel, onAddLocation, isEditMode, selectedIndex, states, recordTypes } = props;
    const classes = props.classes;

    /*** Get selected location functions ***/
    const getSelectedLocation = locationId =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(fetchSelectedLocation(locationId));
            }, 1000);
        });
    /*** End get selected location functions ***/


    /*** Locations search functions ***/
    const filterLocations = async searchTerm => {
        if (searchTerm.length >= 3) {
            let result = null;
            await locationSearch(searchTerm)
                .then(response => result = response);
            const facilities = result.data.map(location => ({
                value: location.locationId,
                label: getLocationLabel(location),
                selectedLabel: location.locationName
            }))
            facilities.unshift({
                label: <Button color="primary" className={classes.buttonLocation} title="If your location was not found, scroll down to click on Add New Location">If your location was not found, scroll down to click on "Add New Location" </Button>
            })
            facilities.push({
                label: <a href="#" className={classes.addNewLocation} onClick={() => {
                    setLocationModalOpen(true)
                }
                } title="Add New Location">Add New Location </a>
            })
            return facilities
        }
        return [];
    }

    const getLocationLabel = loc => {
        let locData = [loc.typeName, loc.locationName, loc.departmentName, loc.address, loc.city, loc.stateName, loc.zipCode];
        return locData.filter(item => { return !!item }).join(' | ');
    }

    const locationOptions = searchTerm =>
        new Promise(resolve => {
            setTimeout(() => {
                resolve(filterLocations(searchTerm));
            }, 1000);
        });


    const handleEditMode = (value) => {
        setIsLocationEditMode(true);
        setLocationModalOpen(true)
    }
    /*** End locations search functions ***/
    const handleCloseDialog = () => {
        setShowDialog(false);
    }
    const defaultLocationValues = {
        locationName: '',
        locationDepartment: '',
        locationAddress: '',
        locationCity: '',
        locationState: null,
        locationZip: '',
        locationPhone: '',
        locationFax: '',
    };

    // sets the location values in the state
    const [locationValues, setLocationValues] = useState(initialData.location);

    const [isLocationModalOpen, setLocationModalOpen] = useState(false);

    const [showDialog, setShowDialog] = useState(false);

    const [isLocationEditMode, setIsLocationEditMode] = useState(false);

    // clear location data.
    // Closes the remove dialog. 
    const handleClearLocationData = () => {
        setLocationValues(defaultLocationValues);
        setShowDialog(false);
    }

    // Handle when cancel button is clicked in create location modal
    const handleCancelAddLocation = () => {
        setLocationModalOpen(false);
    }

    // Sets the location values to the data passed from the add location modal.
    // Closes the add location modal. 
    const handleAddLocation = (values) => {
        setLocationValues({ ...values, locationId: 0 });
        setLocationModalOpen(false);
        setIsLocationEditMode(false)
    }

    const LocationDetailsLabel = styled('div')({
        paddingRight: '300px',
        fontWeight: 700,
        fontSize: '1.1rem',
        paddingBottom: '5px'
    });

    const LocationHeader = styled('div')({
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 700,
        marginRight: '5px'
    });

    const isValidDate = (value) => {

        return value.toString() !== 'Invalid Date';
    }

    const isGreateThanMinDate = (value) => {

        return value > new Date('12/31/1899');
    }

    const isFutureDate = (value) => {
        return value > new Date();
    }

    return (
        <div className={classes.root}>
            <div className={classes.formWrapper}>
                <Formik
                    initialValues={{
                        ...initialData
                    }}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                        onAddLocation({ ...values, location: locationValues }, selectedIndex);
                        setSubmitting(false);
                    }}

                    validationSchema={
                        Yup.object().shape({
                            locationId: Yup.string()
                                .strict(false)
                                .trim()
                                .required('Required'),
                            fromDate: Yup
                                .mixed()  // We use mixed here instead of date so that 
                                // we don't cause a yup typeError to invalidate 
                                // the form and display an error when the value is null.
                                .test("fromDateRequiredWhenAllRecordsFalse", "Required", function (value) {
                                    const { allRecords } = this.parent;

                                    if (!allRecords && !value)
                                        return false;

                                    return true;
                                })
                                .test("FromDateInvalidDate", "Invalid date format.", function (value) {
                                    const { allRecords } = this.parent;


                                    if (!allRecords && value) {
                                        return isValidDate(value);
                                    }

                                    return true;
                                })
                                .test("FromDateGreaterThanMinDate", "Dates must be later than 12/31/1899", function (value) {
                                    const { allRecords } = this.parent;

                                    if (!allRecords && value) {
                                        return isGreateThanMinDate(value);
                                    }

                                    return true;
                                })
                                .test("FromDateFutureDatesAreNotAllowed", "Future dates are not allowed.", function (value) {
                                    const { allRecords } = this.parent;


                                    if (!allRecords && value) {
                                        return !isFutureDate(value)
                                    }

                                    return true;
                                }),
                            toPresent: Yup
                                .mixed(),
                            toDate: Yup
                                .mixed()
                                .test("toDateRequiredWhenAllRecordsOrToPresentNotSet", "Required", function (value) {
                                    const { allRecords, toPresent } = this.parent;

                                    if (!allRecords && !toPresent && !value)
                                        return false;

                                    return true;
                                })
                                .test("ToDateInvalidDate", "Invalid date format.", function (value) {
                                    const { allRecords, toPresent } = this.parent;


                                    if (!allRecords && !toPresent && value) {
                                        return isValidDate(value);
                                    }

                                    return true;
                                })
                                .test("ToDateGreaterThanMinDate", "Dates must be later than 12/31/1899", function (value) {
                                    const { allRecords, toPresent } = this.parent;

                                    if (!allRecords && !toPresent && value) {
                                        return isGreateThanMinDate(value);
                                    }

                                    return true;
                                })
                                .test("ToDateFutureDatesAreNotAllowed", "Future dates are not allowed.", function (value) {
                                    const { allRecords, toPresent } = this.parent;


                                    if (!allRecords && !toPresent && value) {
                                        return !isFutureDate(value)
                                    }

                                    return true;
                                })
                                .test("toDateMustBeGreaterThanFromDate", "To date must be later than the from date.", function (value) {
                                    const { fromDate, toPresent, allRecords } = this.parent;


                                    if (!fromDate || toPresent || allRecords)
                                        return true;

                                    if (fromDate && value) {
                                        const fromDateCopy = new Date(fromDate.valueOf());
                                        const toDateCopy = new Date(value.valueOf());

                                        fromDateCopy.setHours(0, 0, 0, 0);
                                        toDateCopy.setHours(0, 0, 0, 0);

                                        if (fromDateCopy > toDateCopy)
                                            return false;
                                    }

                                    return true;
                                }),
                            records: Yup
                                .array()
                                .min(1, "Please Select at least one record type")
                                .of(Yup.object().shape({
                                    value: Yup.mixed()
                                        .strict(false)
                                        .required('Required'),
                                    scopeDetails: Yup.string()
                                        .strict(false)
                                        .trim()
                                        .min(1, "Please enter at least 1 character up to a maximum of 2048")
                                        .max(2048, "Please enter at least 1 character up to a maximum of 2048")
                                        .required('Required')
                                }))
                        })
                    }
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        } = props;
                        return (
                            <div>
                                <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                                    <Card className={classes.card} >
                                        <CardContent>
                                            <FormControl fullWidth margin="normal">
                                                <FormControl required className={classes.formControl} fullWidth margin="normal">
                                                    <MySelect
                                                        error={errors.locationId && touched.locationId}
                                                        helperText={(errors.locationId && touched.locationId) && errors.locationId}
                                                        name="locationId"
                                                        id="facility"
                                                        placeholder=""
                                                        label="Facility Search *"
                                                        async="true"
                                                        isLoading={false}
                                                        enableTooltip={true}
                                                        customValue={locationValues.locationName}
                                                        isCacheable={false}
                                                        disabled={locationValues.locationName.length > 0 && !values.locationId}
                                                        options={searchTerm => searchTerm.length >= 3 ? locationOptions(searchTerm) : {}}
                                                        handleChange={loc => {
                                                            if (loc) { loc.label = loc.selectedLabel }
                                                            const selectedLocation = getSelectedLocation(loc ? loc.value : 0);
                                                            selectedLocation.then(response => {
                                                                setLocationValues({
                                                                    locationName: response ? response.name : '',
                                                                    locationDepartment: response ? response.departmentName : '',
                                                                    locationAddress: response ? response.address : '',
                                                                    locationCity: response ? response.city : '',
                                                                    locationState: response ? states.find(s => { return s.value === response.stateId }) : null,
                                                                    locationZip: response ? response.zipCode : '',
                                                                    locationPhone: response ? response.phone : '',
                                                                    locationFax: response ? response.fax : ''
                                                                });
                                                                setFieldValue('locationId', response ? response.id : '');
                                                            });
                                                        }}
                                                    />
                                                </FormControl>
                                                {locationValues.locationName.length > 0 &&
                                                    <FormControl className={classes.formControl} fullWidth margin="normal">
                                                        <div className={classes.locationDetailheader}>
                                                            <LocationDetailsLabel>
                                                                Location Details:
                                                            </LocationDetailsLabel>
                                                            {(locationValues.locationName.length > 0 && parseInt(values.locationId) === 0) ?
                                                                <div>
                                                                    <Tooltip title="Edit">
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => handleEditMode(true)}>
                                                                            <Edit />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Remove">
                                                                        <IconButton
                                                                            color="secondary"
                                                                            onClick={() => setShowDialog(true)}>
                                                                            <Delete />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>

                                                        <FormLabel className={classes.locationName}>{locationValues.locationName}</FormLabel>
                                                        {locationValues.locationDepartment && locationValues.locationDepartment.length > 0 &&
                                                            <FormControlLabel
                                                                sx={{ marginLeft: '0px' }}
                                                                className={classes.formControlLabel}
                                                                control={<LocationHeader>Department:</LocationHeader>}
                                                                label={locationValues.locationDepartment}
                                                            />

                                                        }
                                                        <FormControlLabel
                                                            sx={{ marginLeft: '0px' }}
                                                            className={classes.formControlLabel}
                                                            control={<LocationHeader>Address:</LocationHeader>}
                                                            label={locationValues.locationAddress + ' ' + locationValues.locationCity + ' ' + (locationValues.locationState ? locationValues.locationState.label + ' ' : '') + locationValues.locationZip}
                                                        />
                                                        <Grid item xs={12} className={classes.grid} >
                                                            <Grid item xs={4} className={classes.locationPhone}>
                                                                <FormControlLabel
                                                                    sx={{ marginLeft: '0px' }}
                                                                    className={classes.formControlLabel}
                                                                    control={<LocationHeader>Ph:</LocationHeader>}
                                                                    label={locationValues.locationPhone}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {locationValues.locationFax.length > 0 &&
                                                                    <FormControlLabel
                                                                        sx={{ marginLeft: '0px' }}
                                                                        className={classes.formControlLabel}
                                                                        control={<LocationHeader>Fax:</LocationHeader>}
                                                                        label={locationValues.locationFax}
                                                                    />}
                                                            </Grid>
                                                        </Grid>
                                                    </FormControl>}
                                                <FormControlLabel
                                                    margin="normal"
                                                    control={
                                                        <Checkbox
                                                            id="allRecords"
                                                            name="allRecords"
                                                            color="primary"
                                                            value={values.allRecords.toString()}
                                                            onChange={handleChange}
                                                            checked={values.allRecords}
                                                        />
                                                    }
                                                    label="Any and All Records"
                                                />
                                                <FormControl className={classes.formControl} fullWidth margin="normal">
                                                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                                                        <Field component={FormikDatePicker} name="fromDate" label={values.allRecords ? "From" : "From *"} maxDate={new Date()} disabled={values.allRecords} />
                                                    </LocalizationProvider>
                                                </FormControl>
                                                <FormControlLabel
                                                    margin="normal"
                                                    control={
                                                        <Checkbox
                                                            id="toPresent"
                                                            name="toPresent"
                                                            color="primary"
                                                            value={values.toPresent.toString()}
                                                            onChange={handleChange}
                                                            disabled={values.allRecords}
                                                            checked={values.toPresent}
                                                        />
                                                    }
                                                    label="To Present"
                                                />
                                                <FormControl className={classes.formControl} fullWidth margin="normal">
                                                    <LocalizationProvider dateAdapter={DateFnsUtils}>
                                                        <Field component={FormikDatePicker} name="toDate" label={values.allRecords || values.toPresent ? "To" : "To *"} maxDate={new Date()} disabled={values.allRecords || values.toPresent} />
                                                    </LocalizationProvider>
                                                </FormControl>
                                                <FormControl required className={classes.formControl} fullWidth margin="normal">
                                                    <MySelect
                                                        error={errors.records && touched.records}
                                                        helperText={(errors.records && touched.records) && errors.records}
                                                        id="records"
                                                        name="records"
                                                        placeholder=""
                                                        label="Record Types *"
                                                        options={recordTypes}
                                                        value={values.records}
                                                        isLoading={false}
                                                        handleChange={(records) => {
                                                            records.forEach(record => { //Reset Scope
                                                                if (!values.records.find(x => x.value === record.value)) {
                                                                    record.scopeDetails = record.scope;
                                                                }
                                                            })
                                                            setFieldValue('records', records);
                                                        }}
                                                        isMulti={true}
                                                    />
                                                </FormControl>
                                                {
                                                    values.records.map((r, i) => {
                                                        return <FormControl key={r.value} required className={classes.formControl} fullWidth margin="normal">
                                                            <TextField
                                                                variant="standard"
                                                                error={!r.scopeDetails ? true : false}
                                                                helperText={r.scopeDetails ? '' : 'Required'}
                                                                id={`records[${i}]scopeDetails}`}
                                                                name={`records[${i}]scopeDetails}`}
                                                                label={`${r.label} Scope Details`}
                                                                required
                                                                className={classes.textField}
                                                                multiline
                                                                rows="5"
                                                                inputProps={{ maxLength: 2048 }}
                                                                value={r.scopeDetails}
                                                                onChange={(e) => {
                                                                    values.records[i].scopeDetails = e.target.value;
                                                                    setFieldValue('records', values.records);
                                                                }}
                                                            />
                                                        </FormControl>
                                                    })
                                                }
                                            </FormControl>
                                        </CardContent>
                                    </Card>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color='secondary'
                                                className={classes.button}
                                                disabled={isSubmitting}
                                                onClick={onCancel}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button color="primary" type="submit" className={classes.button} variant="contained" disabled={isSubmitting} >
                                                {isEditMode ? 'Save' : "Add"}
                                            </Button>
                                            {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </Grid>
                                    </Grid>
                                </form>
                                <Dialog
                                    open={isLocationModalOpen}
                                    onClose={() => setLocationModalOpen(false)}
                                    aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">
                                        {isLocationEditMode ? "Edit New Location" : "Create New Location"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <CreateLocationModal
                                            initialData={locationValues}
                                            onCancel={handleCancelAddLocation}
                                            onAddLocation={values => {
                                                setFieldValue('locationId', 0);
                                                handleAddLocation(values);
                                            }
                                            }
                                            isEditMode={isLocationEditMode}
                                            states={states}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    open={showDialog}
                                    onClose={() => setShowDialog(false)}
                                    aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">
                                        CLEAR LOCATION
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure, you want this location details to be cleared?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setFieldValue('locationId', '');
                                                handleClearLocationData()
                                            }
                                            }>
                                            Yes
                                        </Button>
                                        <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                            No
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
export default withStyles(styles)(LocationInformationModal);