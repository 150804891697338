import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter } from "react-router-dom";
import OrderForm from './Components/OrderForm/OrderForm';
import Error from './Components/Core/Error';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import pink from '@mui/material/colors/pink';
import blue from '@mui/material/colors/blue';
import Login from './Components/Core/Login/Login';
import ProtectedRoute from './Components/Core/ProtectedRoute';
import { getUser } from './Services/AuthService';
import GuidewireWorkStatus from './Components/OrderStatus/OrderStatus';
import './AxiosInterceptors';
import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import TokenFetchError from './Components/Core/TokenFetchError';
import SessionExpiredMessage from './Components/Core/SessionExpiredMessage';

// Register filepond plugins
FilePond.registerPlugin(FilePondPluginFileValidateType);
FilePond.registerPlugin(FilePondPluginFileValidateSize);

const palette = {
    primary: blue,
    secondary: pink
}

const themeName = 'Ontellus Colors';

const theme = createTheme({
    palette,
    themeName,
    typography: {
        useNextVariants: true,
    },
    styleOverrides: {
        MuiInput: {
            underline: {
                "&&&&:hover:before": {
                    borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
                }
            }
        }
    }
});

const App = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isFetchUserComplete, setIsFetchUserComplete] = useState(false);

    useEffect(() => {

        const fetchUser = async () => {
            const user = await getUser();

            if (user != null && user.access_token && !user.expired) {
                setIsAuthenticated(true);
            }

            setIsFetchUserComplete(true);
        }

        fetchUser();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <div>
                    {!isFetchUserComplete ? (
                        <div>Please wait...</div>
                    ) : (
                        <Routes>
                            <Route path="/login" element={<Login isAuthenticated={isAuthenticated} />} />
                            <Route path="/error" element={<Error />} />
                            <Route path="/tokenfetcherror" element={<TokenFetchError />} />
                            <Route path="/sessiontimeout" element={<SessionExpiredMessage />} />
                            <Route exact path="/" element={<ProtectedRoute isAuthenticated={isAuthenticated}  />} >
                                <Route path="/" element={<OrderForm/>} />
                                <Route path={"/order/status/:id"} element={<GuidewireWorkStatus/>} />
                            </Route>
                        </Routes>
                    )
                    }
                </div>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
