import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { withStyles } from '@mui/styles';

const styles = theme => ({
    root: {
        margin: 20,
        padding: 20
    },
});

const TokenFetchError = (props) => {

    const location = useLocation();
    const carrier = location.state && location.state.search ? location.state.search : location.search;
    const from = location.state ? `${location.state.pathname}${carrier}` : `/${carrier}`;
    return (
        <div >
            We were unable to renew your session. <Link to={from} >Click here</Link> log in again.
        </div>
    );
}

export default withStyles(styles)(TokenFetchError);

