import axios from 'axios';

const settings = window.APP_SETTINGS
const SMARTCHRON_FILETYPE_CODENO = 7128;

// TODO: Handle renewel login here properly.

export const fetchAuths = async () => {
    try {
        const url = `${settings.MYO_API_BASE_URL}/authforms`
        const resp = await axios.get(url);
        console.log(resp);
    }
    catch (err) {
        console.log(err);
    }

}



export const createAuth = (postModel) => {

    const { claimNumber, rush, claimant, locations, allowAddFacilityNamesToHipaaAuth, uploadedFiles, claimId, participantId } = postModel;

    const url = `${settings.MYO_API_BASE_URL}/cases/authorizations`;

    const locs = [];

    locations.forEach(l => {
        l.records.forEach(r => {
            locs.push({
                locationId: l.locationId,
                allRecords: l.allRecords,
                toPresent: l.toPresent,
                fromDate: l.fromDate,
                toDate: l.toDate,
                recordTypeId: r.value,
                scope: r.scopeDetails,
                locationInformation: {
                    locationName: l.location.locationName,
                    streetAddress: l.location.locationAddress,
                    city: l.location.locationCity,
                    state: l.location.locationState.value,
                    zip: l.location.locationZip,
                    phone: l.location.locationPhone,
                    fax: l.location.locationFax
                }
            });
        })
    });
    return axios({
        method: 'post',
        url: url,
        data: {
            claimNumber,
            rush,
            claimant,
            recordLocations: locs,
            allowAddFacilityNamesToHipaaAuth,
            uploadedFiles,
            claimId,
            participantId
        }
    });
}

export const deleteCase = (deleteModel) => {

    var { caseNo, reason } = deleteModel;

    const url = `${settings.MYO_API_BASE_URL}/cases/${caseNo}?reason=${reason}`

    return axios({
        method: 'delete',
        url: url
    }).then(function () {
        console.info(`Case deleted successfully : ${caseNo}`);
    }).catch(function (error) {
        console.error(`Error deleting case ${caseNo} : ${error}`);
    });
}

export const addLog = (logModel) => {

    const { caseNo, errors } = logModel;

    const url = `${settings.MYO_API_BASE_URL}/logs`

    return axios({
        method: 'post',
        url: url,
        data: {
            caseNo,
            errors
        }
    }).then(function () {
        console.info(`Added log successfully for case: ${caseNo}`);
    }).catch(function (error) {
        console.error(`Error adding log for case: ${caseNo}, error : ${error}`);
    });
}

/*** File download functions ***/

export const getDonwloadLink = async (fileId) => {
    const result = await axios.post(`${settings.MYO_API_BASE_URL}/files/sign?fileId=${fileId}`);
    return result.data;
}

/*** End File download functions  ***/

/*** Cases functions ***/
export const fetchCase = async (caseId) => {
    const result = await axios.get(`${settings.MYO_API_BASE_URL}/cases/${caseId}`);
    return result.data;
}

export const fetchMappedCase = async (caseId) => {
    const result = await fetchCase(caseId);

    if (result != null) {
        const fileList = [];

        result.recordLocations.forEach(l => fileList.push(...l.files));

        if (result.firmPreferences.caseChronEnabled) {
            result.files.forEach(file => {
                if (file.typeId === SMARTCHRON_FILETYPE_CODENO) {
                    fileList.push(file);
                }
            });
        }

        const caseDto = {
            orderNumber: result.caseId,
            orderDate: new Date(result.orderDate).toLocaleDateString(),
            patientName: result.patientName,
            patientDob: new Date(result.patientDob).toLocaleDateString(),
            patientSSN: result.patientSsn,
            patientClaimNumber: result.claimNo,
            rush: result.rush ? 'Yes' : 'No',
            addonEnabled: result.addonEnabled,
            recordLocations: result.recordLocations.map(l => {
                return {
                    providerName: l.name,
                    providerRecordType: l.recordType,
                    providerDateRange: l.dateRange,
                    providerScope: l.scope,
                    providerAddress: l.address,
                    providerCity: l.city,
                    providerState: l.state,
                    providerPostalCode: l.zipCode,
                    providerPhone: l.phone,
                    providerFax: l.fax,
                    status: l.status,
                    remarks: l.logs,
                    providerRush: l.rush ? 'Yes' : 'No'
                }
            }),
            documents: fileList
        };

        return caseDto;
    } else {
        return {
            orderNumber: '',
            orderDate: '',
            patientName: '',
            patientDob: '',
            patientSSN: '',
            patientClaimNumber: '',
            rush: '',
            recordLocations: [],
            documents: []
        };
    }
}
/*** End Cases functions  ***/

/*** States functions ***/
export const fetchStates = async () => {
    const result = await axios.get(`${settings.MYO_API_BASE_URL}/states`);
    return result.data;
}

export const fetchMappedStates = async () => {
    const result = await fetchStates();
    return result.map(state => ({
        value: state.id,
        label: state.name
    }));
}
/*** End States functions  ***/

/*** Record Type functions ***/
export const fetchRecordTypes = async () => {
    const result = await axios.get(`${settings.MYO_API_BASE_URL}/recordtypes`);
    return result.data;
}

export const fetchMappedRecordTypes = async () => {
    const result = await fetchRecordTypes();
    return result.map(recordType => ({
        value: recordType.id,
        label: recordType.name,
        scopeDetails: recordType.scopeDetails,
        scope: recordType.scopeDetails
    }));
}
/*** End Record Type functions  ***/

/*** Location functions ***/
export const createLocation = async (locationModel) => {
    const { locationName, streetAddress, city, state, zip, phone, fax } = locationModel;

    const url = `${settings.MYO_API_BASE_URL}/locations`

    return await axios({
        method: 'post',
        url: url,
        data: {
            locationName,
            streetAddress,
            city,
            state,
            zip,
            phone,
            fax
        }
    });
}

/*** Document Type functions ***/
export const fetchDocumentTypes = async () => {
    const result = await axios.get(`${settings.MYO_API_BASE_URL}/documenttypes`);
    return result.data;
}

export const fetchMappedDocumentTypes = async () => {
    const result = await fetchDocumentTypes();
    return result.map(documentType => ({
        value: documentType.id,
        label: documentType.name
    }));
}


/*** Fetch selected location ***/
export const fetchSelectedLocation = async (locationId) => {
    let location = null
    if (locationId > 0) {
        await axios.get(`${settings.MYO_API_BASE_URL}/locations`, {
            params: {
                locationId: locationId
            }
        }).then(response => location = response.data);
    }
    return location;
}

/*** location search ***/
export const locationSearch = (searchTerm) => {
    return axios.get(`${settings.MYO_API_BASE_URL}/locations/search`, {
        params: {
            SearchTerm: searchTerm
        }
    });
}

export const getClientManager = async () => {
    const result = await axios.get(`${settings.MYO_API_BASE_URL}/users/clientManager`);
    return result.data;
}

export const createCaseParts = async (postModel) => {
    const { caseNo, locations, uploadedFiles } = postModel;

    const url = `${settings.MYO_API_BASE_URL}/cases/caseparts`;

    const locs = [];

    locations.forEach(l => {
        l.records.forEach(r => {
            locs.push({
                locationId: l.locationId,
                allRecords: l.allRecords,
                toPresent: l.toPresent,
                fromDate: l.fromDate,
                toDate: l.toDate,
                recordTypeId: r.value,
                scope: r.scopeDetails,
                locationInformation: {
                    locationName: l.location.locationName,
                    streetAddress: l.location.locationAddress,
                    city: l.location.locationCity,
                    state: l.location.locationState.value,
                    zip: l.location.locationZip,
                    phone: l.location.locationPhone,
                    fax: l.location.locationFax
                }
            });
        })
    })
    return axios({
        method: 'post',
        url: url,
        data: {
            caseNo,
            recordLocations: locs,
            uploadedFiles
        }
    });
}

