import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';


const ProtectedRoute = (props) => {
  const location = useLocation();
  const { isAuthenticated } = props;
  return isAuthenticated 
  ? <Outlet location />
  : <Navigate to="/login" state={location} />;
}

export default ProtectedRoute;