import axios from 'axios';
import { ClientConsts } from '../Utils/Consts';
import { getCarrierName, getUrlParamByName } from './AuthService';
const settings = window.APP_SETTINGS

class DuckCreekApiService {

      static isSessionActive() {
        // Since the duckcreek integration is done via backend calls and the parent frame's session is not relevant we always return true.
        return true;
    }

    static async getRelatedToInfo() {
        const duckCreekFTKey = getUrlParamByName(ClientConsts.DuckCreekFTKey);

        const result = await axios.get(`${settings.MYO_API_BASE_URL}/claimcenter/duckcreek/formprefill/${getCarrierName()}`,{params :{prefillOnetimeToken:duckCreekFTKey}});
        return result.data;
    }

    static createCase() {
        // The order is sent to duckcreek via a backend call so nothing to do here
        return Promise.resolve();
    }

    static showRelatedTo()
    {  
       return false;
    }

    static getCaseStatus(caseNo) {
        return false;
    }
}

export default DuckCreekApiService;