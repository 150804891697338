const CarrierConsts = {
    CarrierKey: "carrier",
    ErieInsuranceParam: "ErieInsurance",
    ErieInsuranceStorageValue: "erie"
};

const ClientConsts = {
    DuckCreekFTKey: "ft"
};

const OrderStatusConsts = {
    REQUESTED: 'REQUESTED',
    INPROGRESS: 'IN_PROGRESS',
    WAITING: 'WAITING',
    CANCELED: 'CANCELED',
    DECLINED: 'DECLINED',
    COMPLETED: 'COMPLETED'
}

const CarrierConifgConsts = {
    Guidewire: 'GuideWire',
    Duckcreek: 'DuckCreek'
}
export { CarrierConsts, ClientConsts, OrderStatusConsts, CarrierConifgConsts };