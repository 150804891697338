import { CarrierConifgConsts } from '../Utils/Consts';
import { getCarrier } from './AuthService';
import DuckCreekApiService from './DuckcreekService';
import GuidewireApiService from './GuidewireService';

export default function FormServiceFactory() {

    var carrier = getCarrier();
    if (carrier && carrier.CarrierConfig === CarrierConifgConsts.Duckcreek) {
        return DuckCreekApiService;
    } else if (carrier && carrier.CarrierConfig === CarrierConifgConsts.Guidewire) {
        return GuidewireApiService;
    } else {
        return GuidewireApiService;
    }
}