import { styled } from "@mui/styles";
import React from "react";
import NumberFormat from "react-number-format";

export const DivHeader = styled('div') ({
    fontWeight: 'bold',
    color: '#232323',
    lineHeight: '30px',
    fontSize: '1rem',
    lineHeight: '1.5em',
    marginBottom: '20px'
});

export const NumberCustomeFormat = React.forwardRef(function NumberCustomeFormat(props, ref) {
    const { onChange, name, format, ...other } = props;
  console.log(props);
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        format={format}
        onValueChange={values => {
          onChange({
            target: {
              name,
              value: values.value
            }
          })
        }}
        
      />
    );
  });