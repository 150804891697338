import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { Formik } from 'formik';
import { fetchMappedCase, getDonwloadLink } from '../../Services/MyoApiService';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableBody';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, Dialog, DialogContent, Toolbar, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Navbar from '../Core/Navbar';
import { useLocation } from 'react-router-dom';
import { DivHeader } from '../Core/CommonComponents';
import FormServiceFactory from '../../Services/FormServiceFactory';
import OrderForm from '../OrderForm/OrderForm';
import { OrderStatusConsts } from '../../Utils/Consts';

const styles = theme => ({
    root: {
        display: "flex",
        font: 'normal 13px/16px "Open Sans",helvetica,sans-serif',
        flexGrow: 1
    },
    formGroup: {
        flexDirection: "row"
    },
    formLabel: {
        width: "100%"
    },
    label: {
        width: "100%",
        paddingLeft: '15px'
    },
    customlabel: {
        width: "100%",
        paddingLeft: '15px',
        fontWeight: 'bold',
    },
    card: {
        marginBottom: '20px',
        overflow: 'hidden'
    },
    grid: {
        borderBottom: ' 1px solid rgb(209, 196, 196)'
    },
    innergrid: {
        display: "inherit"
    },
    formControlLabel: {
        width: '100%',
        marginLeft: '0px',
    },
    legend: {
        fontWeight: 'bold',
        width: '275px',
        textAlign: 'right',
        color: '#232323',
        fontSize: '15px'
    },
    tableHeaders: {
        fontWeight: 'bold',
        color: '#232323'
    },
    textAlignLeft: {
        textAlign: 'left'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
});

// Donwload file
const onDownloadClick = (fileId) => {
    getDonwloadLink(fileId).then((res) => {
        window.location = res.url;
    });
}

const OrderStatus = (props) => {
    const { classes } = props;

    const FormService = FormServiceFactory();

    const location = useLocation();

    const queryStr = location.pathname;
    const str = queryStr.split("/");
    const caseId = parseInt(str[3]);

    const [form, setFormValues] = useState({});
    const [isOntellusTabLoading, setOntellusTabLoading] = useState(true);
    const [isFetchingCaseFailed, setFetchingCaseFailed] = useState(false);
    const [isFetchingCaseForbidden, setFetchingCaseForbidden] = useState(false);

    const [isAddToOrderAllowed, setAddToOrderAllowed] = useState(false);
    const [isAddToOrderModalOpen, setAddToOrderModalOpen] = useState(false);

    useEffect(() => { 
        /*
        Adding if condition for performance enhancement so the fetching and getStatus 
        happens only one time when the form is loading or user-submitted new locations to the order
        */
        if (isOntellusTabLoading) {
            Promise.all([fetchMappedCase(caseId),
            FormService.getCaseStatus(caseId)])
                .then(response => {
                    setFormValues(response[0]);
                    setFetchingCaseFailed(false);
                    setFetchingCaseForbidden(false);
                    setOntellusTabLoading(false);
                    if (response[0].addonEnabled && (response[1].code == OrderStatusConsts.INPROGRESS ||
                        response[1].code == OrderStatusConsts.WAITING)) {
                        setAddToOrderAllowed(true);
                    }
                }, error => {
                    if (error.response.status === 403) {
                        setFetchingCaseForbidden(true);
                    } else {
                        setFetchingCaseFailed(true);
                    }
                    setOntellusTabLoading(false);
                });
        }
    }, [isOntellusTabLoading]);

    return (
        <div className={classes.root}>
            {isOntellusTabLoading ?
                <LinearProgress
                    style={{ width: '100%' }}
                />
                :
                <div className="">
                    {(isFetchingCaseFailed || isFetchingCaseForbidden) ?
                        <div className="" style={{ 'fontWeight': 'bold', 'fontSize': '14px', paddingTop: '15px', paddingLeft: '50px' }}>
                            {isFetchingCaseFailed &&
                                <p>An error has occurred while fetching data on the selected Service Request.  Please try again.</p>
                            }
                            {isFetchingCaseForbidden &&
                                <p> In order to view details on the Service Request, you need to be a Scheduling Party on the case.</p>
                            }
                        </div>
                        :

                        <Formik
                            initialValues={{
                                ...form
                            }}
                            enableReinitialize={true}
                        >
                            {(props) => {
                                const {
                                    values,
                                } = props;
                                return (
                                    <div>
                                        <Navbar title="Ontellus Records Status" />
                                        <FormControl component="fieldset" sx={{ margin: '10px' }}>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <DivHeader>
                                                        ORDER INFORMATION
                                                    </DivHeader>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                classes={{ label: classes.customlabel }}
                                                                className={classes.formControlLabel}
                                                                control={<FormLabel component="legend" className={classes.legend}>Order#:</FormLabel>}
                                                                label={values.orderNumber}
                                                            />
                                                            <FormControlLabel
                                                                classes={{ label: classes.label }}
                                                                className={classes.formControlLabel}
                                                                control={<FormLabel component="legend" className={classes.legend}>Order Date:</FormLabel>}
                                                                label={values.orderDate}
                                                            />
                                                            <FormControlLabel
                                                                classes={{ label: classes.label }}
                                                                className={classes.formControlLabel}
                                                                control={<FormLabel component="legend" className={classes.legend}>Rush:</FormLabel>}
                                                                label={values.rush}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <DivHeader>
                                                        CLAIMANT INFORMATION
                                                    </DivHeader>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                classes={{ label: classes.label }}
                                                                className={classes.formControlLabel}
                                                                control={<FormLabel component="legend" className={classes.legend}>Name:</FormLabel>}
                                                                label={values.patientName}
                                                            />
                                                            <FormControlLabel
                                                                classes={{ label: classes.label }}
                                                                className={classes.formControlLabel}
                                                                control={<FormLabel component="legend" className={classes.legend}>DOB:</FormLabel>}
                                                                label={values.patientDob}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControlLabel
                                                                classes={{ label: classes.label }}
                                                                className={classes.formControlLabel}
                                                                control={<FormLabel component="legend" className={classes.legend}>SSN:</FormLabel>}
                                                                label={values.patientSSN}
                                                            />
                                                            <FormControlLabel
                                                                classes={{ label: classes.label }}
                                                                className={classes.formControlLabel}
                                                                control={<FormLabel component="legend" className={classes.legend}>Claim Number:</FormLabel>}
                                                                label={values.patientClaimNumber}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid container justifyContent="space-between" >
                                                            <Grid item>
                                                                <DivHeader>
                                                                    LOCATIONS INFORMATION
                                                                </DivHeader>

                                                            </Grid>
                                                            {
                                                                isAddToOrderAllowed && <Grid className={classes.addLocationDiv} item>
                                                                    <Button variant="contained" color="info"
                                                                        onClick={() => setAddToOrderModalOpen(true)}
                                                                        type='button'
                                                                    >
                                                                        Add Locations to Order
                                                                    </Button>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                        {
                                                            values.recordLocations && values.recordLocations.map((l, i) => {
                                                                return <Accordion key={i}>
                                                                    <AccordionSummary sx={{ backgroundColor: '#f8f4f5' }} expandIcon={<ExpandMoreIcon />}>
                                                                        <Typography className={classes.heading}><b>{l.providerName}</b>({l.providerRecordType})</Typography>
                                                                        <Typography className={classes.secondaryHeading}>
                                                                            {l.status}
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails className={classes.innergrid}>
                                                                        <Grid container >
                                                                            <Grid item xs={6}>
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Name:</FormLabel>}
                                                                                    label={l.providerName}
                                                                                />
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Record Type:</FormLabel>}
                                                                                    label={l.providerRecordType}
                                                                                />
                                                                            </Grid>

                                                                            <Grid item xs={6}>
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Rush:</FormLabel>}
                                                                                    label={values.rush}
                                                                                />
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Date Range:</FormLabel>}
                                                                                    label={l.providerDateRange}
                                                                                />
                                                                            </Grid>

                                                                        </Grid>
                                                                        <Grid container>
                                                                            <FormControlLabel
                                                                                classes={{ label: classes.label }}
                                                                                className={classes.formControlLabel}
                                                                                style={{ paddingBottom: '13px', paddingTop: '13px' }}
                                                                                control={<FormLabel component="legend" style={{ 'width': '236px' }} className={classes.legend}>Scope:</FormLabel>}
                                                                                label={l.providerScope}
                                                                            />
                                                                        </Grid>
                                                                        <Grid container >
                                                                            <Grid item xs={6}>
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Address:</FormLabel>}
                                                                                    label={l.providerAddress}
                                                                                />
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>State:</FormLabel>}
                                                                                    label={l.providerState}
                                                                                />
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Phone:</FormLabel>}
                                                                                    label={l.providerPhone}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>City:</FormLabel>}
                                                                                    label={l.providerCity}
                                                                                />
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Postal Code:</FormLabel>}
                                                                                    label={l.providerPostalCode}
                                                                                />
                                                                                <FormControlLabel
                                                                                    classes={{ label: classes.label }}
                                                                                    className={classes.formControlLabel}
                                                                                    control={<FormLabel component="legend" className={classes.legend}>Fax:</FormLabel>}
                                                                                    label={l.providerFax}
                                                                                />
                                                                            </Grid>

                                                                        </Grid>
                                                                        <Grid container>
                                                                            <FormControlLabel
                                                                                classes={{ label: classes.label }}
                                                                                className={classes.formControlLabel}
                                                                                style={{ paddingBottom: '13px', paddingTop: '13px' }}
                                                                                control={<FormLabel component="legend" style={{ 'width': '236px' }} className={classes.legend}>ONTELLUS REMARKS:</FormLabel>}
                                                                                label={
                                                                                    <Grid item xs={12}>
                                                                                        {
                                                                                            l.remarks && l.remarks.length > 0 ? (
                                                                                                l.remarks.map(r => (
                                                                                                    <Typography key={r.recordLocationLogId}>
                                                                                                        [{new Date(r.dateEntered).toLocaleDateString()}] {r.remarks}
                                                                                                    </Typography>
                                                                                                ))) : null
                                                                                        }
                                                                                    </Grid>
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </AccordionDetails>
                                                                </Accordion>

                                                            })
                                                        }
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                            <Card className={classes.card}>
                                                <CardContent>
                                                    <Grid container>
                                                        <DivHeader>
                                                            RECORDS OBTAINED
                                                        </DivHeader>
                                                        {values.documents && values.documents.length > 0 ? (
                                                            <Grid container className={classes.grid} >
                                                                <Grid item xs={6}>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell className={classes.tableHeaders}>Document&nbsp;Name</TableCell>
                                                                                <TableCell className={classes.tableHeaders}>Doc&nbsp;Type</TableCell>
                                                                                <TableCell className={classes.tableHeaders}>Upload&nbsp;Date</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {values.documents.map(document => (
                                                                                <TableRow key={document.fileId}>
                                                                                    <TableCell>
                                                                                        <Button className={classes.textAlignLeft} color="primary" onClick={() => onDownloadClick(document.fileId)}>{document.name}</Button>
                                                                                    </TableCell>
                                                                                    <TableCell>{document.type}</TableCell>
                                                                                    <TableCell>{new Date(document.dateEntered).toLocaleDateString()}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Grid container className={classes.grid} >
                                                                <Grid item xs={6}>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell>
                                                                                    No records to show
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </FormControl>
                                        <Dialog
                                            fullWidth
                                            maxWidth='lg'
                                            disableEscapeKeyDown
                                            open={isAddToOrderModalOpen}>
                                            <DialogContent className={classes.overflowVisible}>
                                                <Toolbar>
                                                    <Typography variant="h6" color="inherit">
                                                        Add Locations to Order ({caseId})
                                                    </Typography>
                                                </Toolbar>
                                                <OrderForm
                                                    caseNo={caseId}
                                                    partsSubmitCallback={() => {
                                                        setOntellusTabLoading(true);
                                                        setAddToOrderModalOpen(false);
                                                    }}
                                                    isCreateCaseParts={true}
                                                    partsCancelCallback={() => {
                                                        setAddToOrderModalOpen(false);
                                                    }}
                                                />

                                            </DialogContent>
                                        </Dialog>
                                    </div>

                                );
                            }}

                        </Formik>
                    }
                </div>
            }
        </div>
    );
}

export default withStyles(styles)(OrderStatus);