import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MySelect from '../Shared/MySelect/MySelect';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  createAuth,
  fetchMappedStates,
  fetchMappedRecordTypes,
  fetchMappedDocumentTypes,
  deleteCase,
  addLog,
  createCaseParts
} from '../../Services/MyoApiService';
import FormServiceFactory from '../../Services/FormServiceFactory';
import { Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { FormikDatePicker } from '../Shared/FormikDatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Banner from '../Core/Banner';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { getCarrierName, getUser } from '../../Services/AuthService';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { CarrierConsts } from '../../Utils/Consts';
import Navbar from '../Core/Navbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import classNames from 'classnames';
import LocationInformationModal from '../LocationInformation/LocationInformation';
import { CardActions, Checkbox, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { DivHeader } from '../Core/CommonComponents';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { useNavigate } from "react-router-dom";
import { LocationOn } from '@mui/icons-material';
import { uploadFile } from "../../Services/FileService";
import moment from 'moment';

const settings = window.APP_SETTINGS;


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  },
  paper: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  caseSectionLabel: {
    paddingTop: theme.spacing.unit * 2,
    fontWeight: 700
  },
  formControlLabel: {
    width: '100%',
    marginLeft: '0px',
    paddingBottom: theme.spacing.unit
  },
  grid: {
    display: "inherit"
  },
  claimantGrid: {
    marginRight: theme.spacing.unit * 3
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  addNewLocation: {
    width: '100%',
    height: '40px',
    paddingTop: '20px'
  },
  formWrapper: {
    padding: '20px'
  },
  uploadFileCard: {
    width: '100%',
    marginBottom: '10px'
  },
  banner: {
    marginTop: 10,
    paddingTop: theme.spacing.unit * 10
  },
  warningMessage: {
    width: '95%',
    marginBottom: '20px',
    padding: '10px',
    color: '#fff',
    background: red[500],
    borderRadius: 3
  },
  confirmationMessage: {
    textAlign: 'center',
    paddingTop: '40px'
  },
  locationsCart: {
    marginTop: '16px',
    maxHeight: '400px',
    overflowX: 'auto'
  },
  error: {
    color: '#d32f2f'
  },
  card: {
    width: '100%',
    marginBottom: '20px',
    overflow: 'hidden'
  },
  locationName: {
    fontWeight: 'bold'
  },
  addLocationDiv: {
    paddingTop: '16px'
  },
  overflowVisible: {
    overflow: 'visible'
  }
});

const OrderForm = (props) => {

  const { isCreateCaseParts, partsSubmitCallback, partsCancelCallback, caseNo } = props;

  const navigate = useNavigate();
  const classes = props.classes;

  // The carrier is set in the local storage during the process of login, check the AuthService
  const FormService = FormServiceFactory();

  const [isFetchUserComplete, setIsFetchUserComplete] = useState(false);
  useEffect(() => {
    const fetchUser = async () => {
      const userObj = await getUser();
      if (userObj != null && userObj.access_token && !userObj.expired) {
        setIsFetchUserComplete(true);
      }

    }
    fetchUser();
  }, []);


  // Check if session is active once the page is loaded
  if (!FormService.isSessionActive()) {
    navigate(`/sessiontimeout${window.location.search}`);
  }

  // Chech if session active every second (1000 milliseconds)
  setInterval(() => {

    if (!FormService.isSessionActive()) {
      navigate(`/sessiontimeout${window.location.search}`);
      //history2.push({ pathname: '/sessiontimeout', from: window.location.pathname });
    }
  }, 1000)

  // Prefill the form from ClaimCenter.
  const defaultPrefilledData = {
    serviceableType: '',
    claimantName: '',
    claimantDob: '',
    claimantSsn: '',
    claimNumber: '',
    claimId: '',
    participantId: ''
  };
  const [prefillData, setPrefillData] = useState(defaultPrefilledData);
  const [serviceableItems, setServiceableItems] = useState([]);
  const [isServiceableItemsLoading, setServiceableItemsLoading] = useState(false);
  const [serviceableSelectedItem, setServiceableSelectedItem] = useState(null);
  //Serviceable Items
  useEffect(() => {
    let defaultValue = null;
    setServiceableItemsLoading(true);
    if (FormService.showRelatedTo()) {
      FormService.getRelatedTo().then((data) => {
        if (data && data.length) {
          data.sort(function (a, b) {
            if (a.displayableName < b.displayableName) { return -1; }
            if (a.displayableName > b.displayableName) { return 1; }
            return 0;
          })
          setServiceableItems(data.map(ListItem => ({
            value: ListItem.id,
            label: ListItem.displayableName,
          })));
          if (data.length === 1) {
            defaultValue = {
              value: data[0].id,
              label: data[0].displayableName
            };
          }
        }
        setServiceableItemsLoading(false);
        if (defaultValue != null) {
          serviceableItemsChange(defaultValue);
        }
      })
    } else {
      serviceableItemsChange({});
    }
  }, [])

  const serviceableItemsChange = e => {
    setServiceableSelectedItem(e);

    const value = e && e.value ? e.value : null;
    if (value || !FormService.showRelatedTo()) {
      FormService.getRelatedToInfo(value).then((data) => {
        setPrefillData({
          serviceableType: value,
          claimantName: data.name,
          claimantDob: data.dob ? new Date(moment.utc(data.dob).format("MM/DD/YYYY").valueOf()) : data.dob,
          claimantSsn: data.ssn,
          claimNumber: data.claimNumber,
          claimId: data.claimId,
          participantId: data.participantId
        });
      })
    } else {
      setPrefillData(defaultPrefilledData);
    }
  }

  // Fetch States function
  const [states, setStates] = useState([]);
  useEffect(() => {
    const fetchStates = async () => {
      const result = await fetchMappedStates();
      setStates(result);
    }
    fetchStates();
  }, []);

  // Fetch RecordTypes
  const [recordTypes, setRecordTypes] = useState([]);
  useEffect(() => {
    const fetchRecordTypes = async () => {
      const result = await fetchMappedRecordTypes();
      setRecordTypes(result);
    }
    fetchRecordTypes();
  }, []);

  const [isEditMode, setIsEditMode] = useState(false);

  const [locations, setLocations] = useState([]);


  // Fetch DocumentTypes
  const [documentTypes, setDocumentTypes] = useState([]);
  useEffect(() => {
    const fetchDocumentTypes = async () => {
      const result = await fetchMappedDocumentTypes();
      setDocumentTypes(result);
    }
    fetchDocumentTypes();
  }, []);

  // Filepond related.
  const filepond = useRef(null);
  const [isFilepondReady, setIsFilepondReady] = useState(true);
  const [files, setFiles] = useState([]);

  const setFilesFromPond = () => {
    const filepondFiles = filepond.current.getFiles();
    const updatedState = filepondFiles.map(fileItem =>
    ({
      fileName: fileItem.filename,
      tempFileName: fileItem.serverId,
      ready: fileItem.status === 5
    })
    );
    setFiles(updatedState);
    // Check if filepond is in a ready state.
    // That means no errored or processing files.
    setIsFilepondReady(filepondFiles.every((file) => file.status === 5));
  }

  const isDuplicateFileName = (fileObj) => {
    // check if a file can be uploaded.      
    return files.find(f => f.fileName === fileObj.file.name) === undefined;
  }

  const mapFileExtension = (source, type) => new Promise((resolve, reject) => {

    var validExtension = !(settings.FORBIDDEN_FILE_EXTENSIONS.split(',').map(forbiddenExtension => {
      return source.name.toLowerCase().endsWith(`.${forbiddenExtension}`);
    }).includes(true));

    if (validExtension) {
      resolve('notExecutable');
    } else {
      resolve('executable');
    }
  });

  const FileTypesList = (props) => {
    const { setFieldValue, handleChange, handleBlur, values } = props;

    files
      .filter(file => file.ready)
      .filter(file => values.uploadTypes.find(f => f.fileName === file.fileName) === undefined)
      .forEach((uploadedFile) => {
        values.uploadTypes.push({
          fileName: uploadedFile.fileName,
          tempFileName: uploadedFile.tempFileName,
          docType: 0
        })
      });
    // update values.uploadTypes to remove deleted files.       
    values.uploadTypes = values.uploadTypes.filter(file => files.find(f => f.fileName === file.fileName))
    //setUploadTypes(values.uploadTypes);
    const handleDocTypeChange = (file, docType) => {
      const documentFile = values.uploadTypes.find(f => f.fileName === file.fileName);
      if (documentFile) {
        documentFile.docType = docType ? docType.value : 0;
        setFieldValue('uploadTypes', values.uploadTypes);
        setUploadTypes(values.uploadTypes);
      }
    }


    return (
      <FieldArray
        fullWidth
        name="uploadTypes"
        render={arrayHelpers => (
          values.uploadTypes.map((file, index) => (
            <Card key={index} sx={{ overflow: 'visible' }} className={classes.uploadFileCard}>
              <CardContent>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <TextField
                    variant="standard"
                    name={`uploadTypes.${index}.fileName`}
                    label="File"
                    value={file.fileName}
                    className={classes.textField}
                    fullWidth
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                </FormControl>
                <FormControl required className={classes.formControl} fullWidth margin="normal">
                  <MySelect
                    name={`uploadTypes.${index}.docType`}
                    handleChange={handleDocTypeChange.bind(this, file)}
                    handleBlur={handleBlur}
                    options={documentTypes}
                    value={documentTypes.find(t => t.value === file.docType)}
                    label="Document Type *"
                    placeholder=""
                    error={file.docType === 0}
                    helperText={file.docType === 0 && 'Required'}
                    isLoading={false} />
                </FormControl>
              </CardContent>
            </Card>
          ))
        )}
      />
    );
  }

  const defaultSelectedLocationValues = {
    records: [],
    allRecords: false,
    fromDate: null,
    toPresent: false,
    toDate: null,
    locationId: '',
    location: {
      locationName: '',
      locationDepartment: '',
      locationAddress: '',
      locationCity: '',
      locationState: null,
      locationZip: '',
      locationPhone: '',
      locationFax: ''
    }
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [selectedLocation, setSelectedLocation] = useState(defaultSelectedLocationValues);

  const [isLocationInformationOpen, setLocationInformationOpen] = useState(false);

  /* Banner messages */
  const [isBannerOpen, setBannerOpen] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [rush, setRush] = useState(false);

  const [allowAddFacilityNamesToHipaaAuth, setAllowAddFacilityNamesToHipaaAuth] = useState(true);

  const [uploadTypes, setUploadTypes] = useState([]);
  // Scroll to the top if the banner is open.
  useEffect(() => {
    if (isBannerOpen)
      window.scrollTo(0, 0);
  }, [isBannerOpen]);

  /* Value to tell if the case has been successfully submitted or not. */
  const [caseSubmitted, setCaseSubmitted] = useState(false);
  useEffect(() => {
    if (caseSubmitted) {
      window.scrollTo(0, 0);
    }
  }, [caseSubmitted]);

  /* Save the case id so it can be displayed to the user in the order confirmation */
  const [caseId, setCaseId] = useState('');

  const handleOrderSubmissionFailure = (caseNo, ccError) => {
    deleteCase({ caseNo, reason: ccError });
    addLog({ errors: [ccError], caseNo: caseNo });
    setBannerMessage("There was an unexpected error creating the order.");
    setBannerOpen(true);
  }

  const handleOpenLocationInformation = (index, location) => {
    setSelectedLocation(location);
    setSelectedIndex(index);
    setIsEditMode(index > -1);
    setLocationInformationOpen(true);
  }

  const [isSubmitting, setSubmitting] = useState(false);

  return (
    !caseSubmitted ? /* If the case is not yet submitted, then show the order form */
      <div className={classes.root}>
        {!isCreateCaseParts && <Navbar title="Ontellus Records Request" />}
        {isBannerOpen &&
          <Banner message={bannerMessage} />
        }
        <div className={classes.formWrapper}>
          <Formik
            initialValues={{
              ...prefillData,
              locations,
              uploadTypes,
              allowAddFacilityNamesToHipaaAuth: true,
            }}

            enableReinitialize={true}

            onSubmit={async (values) => {
              setSubmitting(true);
              if (FormService.isSessionActive()) {
                let dateUtil = new DateFnsUtils()
                let mappedLocations =
                  locations && Array.isArray(locations) && locations.length > 0
                    ? locations.map((l) => ({
                      ...l,
                      fromDate: l.fromDate
                        ? dateUtil.format(l.fromDate, "keyboardDate")
                        : null,
                      toDate: l.toDate
                        ? dateUtil.format(l.toDate, "keyboardDate")
                        : null,
                    }))
                    : [];
                if (!isCreateCaseParts) {
                  createAuth({
                    claimNumber: values.claimNumber,
                    rush,
                    claimant: {
                      name: values.claimantName,
                      dob: values.claimantDob ? dateUtil.format(values.claimantDob, "keyboardDate") : null,
                      ssn: values.claimantSsn,
                    },
                    locations: mappedLocations,
                    allowAddFacilityNamesToHipaaAuth,
                    uploadedFiles: uploadTypes,
                    claimId: values.claimId,
                    participantId: values.participantId
                  }).then(function (resp) {

                    FormService.createCase(resp.data.orderId, serviceableSelectedItem.value).then(function () {
                      setSubmitting(false);
                      setCaseId(resp.data.orderId);
                      setCaseSubmitted(true);

                    }).catch(function (err) {
                      handleOrderSubmissionFailure(resp.data.orderId, `Sending the order to claimcenter failed with error ${err}`);
                      setSubmitting(false);
                    });

                  }).catch(function (err) {
                    // Calls to MYO API failed. TODO: read problem details if they exist.
                    setBannerMessage("There was an unexpected error creating the order.");
                    setBannerOpen(true);
                    setSubmitting(false);
                  });
                } else {
                  createCaseParts({
                    caseNo,
                    locations: mappedLocations,
                    uploadedFiles: uploadTypes,
                  }).then(function (response) {
                    setSubmitting(false);
                    partsSubmitCallback();
                  }).catch(function (error) {
                    setBannerMessage(`There was an unexpected error while adding location to order ${caseNo}.`);
                    setBannerOpen(true);
                    setSubmitting(false);
                  });
                }

              } else {
                navigate(`/sessiontimeout${window.location.search}`);
              }


            }}

            validationSchema={

              Yup.object().shape({
                locations: Yup.array()
                  .min(1, "Please enter at least one location"),
                serviceableType: Yup.string()
                  .strict(false)
                  .trim()
                  .when({
                    is: () => !FormService.showRelatedTo() || isCreateCaseParts,
                    then: Yup.string().nullable(),
                    otherwise: Yup.string().required('Required')
                  }),
                claimantName: Yup.string()
                  .strict(false)
                  .trim()
                  .min(1, "Please enter at least 1 character up to a maximum of 80")
                  .max(80, "Please enter at least 1 character up to a maximum of 80")
                  .when({
                    is: () => isCreateCaseParts,
                    then: Yup.string().nullable(),
                    otherwise: Yup.string().required('Required')
                  }),
                claimantDob: Yup
                  .date()
                  .max(new Date(), "Future dates are not allowed.")
                  .min(new Date('12/31/1899'), "Dates must be later than 12/31/1899")
                  .when({
                    is: () => isCreateCaseParts,
                    then: Yup.date().nullable(),
                    otherwise: Yup.date().typeError('Required').required('Required')
                  }),
                claimantSsn: Yup.string()
                  .strict(false)
                  .trim()
                  .min(4, "Invalid Data")
                  .max(4, "Invalid Data")
                  .when({
                    is: () => isCreateCaseParts,
                    then: Yup.string().nullable(),
                    otherwise: Yup.string().required('Required')
                  }),
                claimNumber: Yup.string()
                  .strict(false)
                  .trim()
                  .min(1, "Please enter at least 1 character up to a maximum of 40")
                  .max(40, "Please enter at least 1 character up to a maximum of 40")
                  .when({
                    is: () => isCreateCaseParts,
                    then: Yup.string().nullable(),
                    otherwise: Yup.string().required('Required')
                  }),
                uploadTypes: Yup
                  .array()
                  .of(
                    Yup.object().shape({
                      fileName: Yup.string().required(),
                      tempFileName: Yup.string().required(),
                      docType: Yup.number().moreThan(0),
                    })
                  )
                  .when({
                    is: () => isCreateCaseParts,
                    then: Yup.array().min(0),
                    otherwise: Yup.array().min(1, 'Please ensure that HIPAA authorization is uploaded')
                  })
              })
            }
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue
              } = props;
              return (
                <div>
                  <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {!isCreateCaseParts && <Card className={`${classes.card} ${classes.overflowVisible}`} >
                      <CardContent>
                        <DivHeader>
                          CLAIMANT INFORMATION
                        </DivHeader>
                        {FormService.showRelatedTo() && <FormControl required className={classes.formControl} fullWidth margin="normal">
                          <MySelect
                            error={errors.serviceableType && touched.serviceableType}
                            helperText={(errors.serviceableType && touched.serviceableType) && errors.serviceableType}
                            id="serviceableType"
                            name="serviceableType"
                            label="Related To *"
                            placeholder=""
                            required
                            options={serviceableItems}
                            value={serviceableSelectedItem}
                            isLoading={isServiceableItemsLoading}
                            disabled={isServiceableItemsLoading}
                            handleChange={(e) => serviceableItemsChange(e)}
                            handleBlur={handleBlur}
                          />
                        </FormControl>}
                        <FormControl fullWidth margin="normal">
                          <Grid container spacing={2} className={classes.grid} >
                            <Grid item xs={6} className={classes.claimantGrid}>
                              <FormControl required className={classes.formControl} fullWidth margin="normal">
                                <TextField
                                  variant="standard"
                                  error={errors.claimantName && touched.claimantName}
                                  helperText={(errors.claimantName && touched.claimantName) && errors.claimantName}
                                  id="claimantName"
                                  name="claimantName"
                                  label="Name"
                                  className={classes.textField}
                                  required
                                  placeholder="First Middle Last Name"
                                  value={values.claimantName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    maxLength: 80
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl required className={classes.formControl} fullWidth margin="normal">
                                <LocalizationProvider dateAdapter={DateFnsUtils}>
                                  <Field component={FormikDatePicker} name="claimantDob" required label="Date of Birth *" className={classes.textField} maxDate={new Date()} onChange={handleChange} />
                                </LocalizationProvider>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className={classes.grid} >
                            <Grid item xs={6} className={classes.claimantGrid}>
                              <FormControl required className={classes.formControl} fullWidth margin="normal">
                                <TextField
                                  variant="standard"
                                  error={errors.claimantSsn && touched.claimantSsn}
                                  helperText={(errors.claimantSsn && touched.claimantSsn) && errors.claimantSsn}
                                  className={classes.textField}
                                  required
                                  label="SSN"
                                  placeholder="Last 4 digits of SSN"
                                  id="claimantSsn"
                                  name="claimantSsn"
                                  onChange={(val) => {
                                    const value = val.target.value;

                                    let ssnRegex = /^[0-9]{0,4}$/;

                                    if (getCarrierName() === CarrierConsts.ErieInsuranceStorageValue) {
                                      ssnRegex = /^[0-9a-zA-Z]{0,4}$/;
                                    }

                                    if (value.match(ssnRegex)) {
                                      setFieldValue('claimantSsn', value);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  value={values.claimantSsn}
                                  InputProps={{
                                    other: {
                                      onValueChange: val => setFieldValue('claimantSsn', val.floatValue),
                                      value: values.claimantSsn,
                                      name: 'claimantSsn'
                                    }
                                  }}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl required className={classes.formControl} fullWidth margin="normal">
                                <TextField
                                  variant="standard"
                                  error={errors.claimNumber && touched.claimNumber}
                                  helperText={(errors.claimNumber && touched.claimNumber) && errors.claimNumber}
                                  id="claimNumber"
                                  name="claimNumber"
                                  label="Claim Number"
                                  disabled
                                  placeholder="Enter Claim Number"
                                  className={classes.textField}
                                  required
                                  value={values.claimNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  inputProps={{
                                    maxLength: 40
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </CardContent>
                    </Card>}
                    <Card className={classes.card}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container justifyContent="space-between" >
                              <Grid item>
                                <DivHeader>
                                  LOCATIONS INFORMATION
                                </DivHeader>

                              </Grid>
                              <Grid className={classes.addLocationDiv} item>
                                <Button variant="outlined" type='button'
                                  startIcon={<AddLocationAltIcon />}
                                  onClick={() => handleOpenLocationInformation(-1, defaultSelectedLocationValues)}>
                                  Add Location
                                </Button>
                              </Grid>
                            </Grid>
                            {!isCreateCaseParts && <FormControlLabel
                              margin="normal"
                              sx={{ marginBottom: '30px' }}
                              control={
                                <Checkbox

                                  color="primary"
                                  value={rush}
                                  onChange={(e) => setRush(e.target.checked)}
                                  checked={rush}
                                />
                              }
                              label="Rush"
                            />}
                          </Grid>

                          <Grid container className={classes.MT2} >
                            <p className={classes.error}>
                              {errors.locations}
                            </p>
                            <Grid container spacing={16}>
                              <Grid item xs={12}>
                                <List component="nav" className={classes.locationsCart}>
                                  {
                                    locations.length ? locations.map((l, index) => {
                                      return <ListItem key={'item_' + index} button onClick={() => {
                                        handleOpenLocationInformation(index, l);
                                      }}>
                                        <Avatar sx={{ marginRight: '10px', background: '#f1f0ef' }}>
                                          <LocationOn color="primary" />
                                        </Avatar>
                                        <ListItemText primary={<Typography component="div" className={classes.locationName}>
                                          {l.location.locationName}
                                        </Typography>} secondary={
                                          <React.Fragment>
                                            <Typography component="span" sx={{ display: 'block' }} className={classes.inline} color="textPrimary">
                                              {l.location.locationAddress + ' ' + l.location.locationCity + ' ' + (l.location.locationState ? l.location.locationState.label + ' ' : '') + l.location.locationZip}
                                            </Typography>
                                            {l.records.map(r => r.label).join(', ')}
                                          </React.Fragment>
                                        } />
                                        <ListItemSecondaryAction>
                                          <Tooltip title={`Remove from cart`}>
                                            <IconButton onClick={() => {
                                              setSelectedIndex(index);
                                              setShowDeleteDialog(true);
                                            }} aria-label="Delete">
                                              <DeleteIcon color="error" />
                                            </IconButton>
                                          </Tooltip>
                                        </ListItemSecondaryAction>
                                      </ListItem>
                                    }) : <FormLabel component="legend" >No Locations Entered</FormLabel>
                                  }

                                </List>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Card className={classes.card} sx={{ overflow: 'visible' }}>
                      <CardContent>
                        <DivHeader>
                          DOCUMENT UPLOAD
                        </DivHeader>

                        <FormControl required className={classes.formControl} fullWidth margin="normal" disabled={isFetchUserComplete}>
                          <FilePond
                            server={{
                              process: (fieldName, file, metadata, load, error, progress, abort) => {
                                const fileInfo = { 'filename': file.name, 'totalFileSize': file.size };
                                setPrefillData(values);

                                const formData = new FormData();
                                formData.append('fileInfo', JSON.stringify(fileInfo));
                                formData.append('file', file);

                                // aborting the request
                                let CancelToken = axios.CancelToken;
                                let source = CancelToken.source();
                                const abortController = new AbortController();

                                uploadFile(
                                  file,
                                  source,
                                  abortController,
                                  progress,
                                  formData
                                )
                                  .then((response) => {
                                    // passing the file id to FilePond
                                    load(response.data);
                                  })
                                  .catch((thrown) => {
                                    if (axios.isCancel(thrown)) {
                                      console.log(
                                        "Request canceled",
                                        thrown.message
                                      );
                                    } else {
                                      console.log(thrown);
                                    }
                                  });

                                // Setup abort interface
                                return {
                                  abort: () => {
                                    source.cancel('Operation canceled by the user.');
                                    abort();
                                  }
                                }
                              }
                            }}
                            ref={filepond}
                            allowMultiple={true}
                            allowReplace={false}
                            allowFileSizeValidation={true}
                            maxFileSize={settings.FILE_UPLOAD_MAX_SIZE_BYTES}
                            labelMaxFileSizeExceeded='File size limitation is less than 2gb'
                            labelMaxFileSize=''
                            required={true}
                            checkValidity={true}
                            beforeAddFile={isDuplicateFileName}
                            fileValidateTypeDetectType={mapFileExtension}
                            fileValidateTypeLabelExpectedTypes='File type is not allowed (.exe, .msi)'
                            acceptedFileTypes={['notExecutable']}
                            onaddfile={(error, fileObj) => {
                              if (!error) {
                                fileObj.setMetadata('filename', fileObj.file.name);
                                fileObj.setMetadata('totalFileSize', fileObj.file.size);
                              }
                            }}
                            onupdatefiles={fileItems => {
                              setFilesFromPond();
                            }}
                            onprocessfiles={() => {
                              setFilesFromPond();
                            }}
                            onerror={(error) => {
                              setFilesFromPond();
                            }}
                            onprocessfilerevert={() => {
                              setFilesFromPond();
                            }}
                            onprocessfile={(error, file) => {
                              setFilesFromPond();
                            }}

                          />
                        </FormControl>

                        {
                          !values.uploadTypes.length && errors.uploadTypes && touched.uploadTypes && <div className={classes.warningMessage}>{errors.uploadTypes}</div>
                        }

                        {FileTypesList({ setFieldValue, handleChange, handleBlur, values })}
                        {!isCreateCaseParts && <FormControl component="fieldset" className={classes.formControl}>
                          <FormLabel component="legend" focused={false}>I authorize Ontellus to insert the facility name(s) submitted with my order on the executed patient HIPAA authorization provided.</FormLabel>
                          <RadioGroup
                            row
                            className={classes.group}
                            value={allowAddFacilityNamesToHipaaAuth.toString()}
                            onChange={(e) => { setAllowAddFacilityNamesToHipaaAuth(e.target.value) }}
                          >
                            <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
                          </RadioGroup>
                        </FormControl>}
                      </CardContent>
                      <CardActions >

                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          {isCreateCaseParts && <Grid item>
                            <Button
                              variant="contained"
                              color='secondary'
                              disabled={isSubmitting}
                              onClick={partsCancelCallback}>
                              Cancel
                            </Button>
                          </Grid>}
                          <Grid item>
                            <Button type="submit" className={classNames(classes.MT2)}
                              color="primary"
                              variant="contained"
                              disabled={isSubmitting || !isFilepondReady}
                              endIcon={isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                            >
                              Submit

                            </Button>
                          </Grid>


                        </Grid>


                      </CardActions>
                    </Card>
                  </form>
                  <Dialog
                    fullWidth
                    maxWidth='md'
                    disableEscapeKeyDown
                    open={isLocationInformationOpen}>
                    <DialogContent className={classes.overflowVisible}>
                      <Toolbar>
                        <Typography variant="h6" color="inherit">
                          {isEditMode ? "Edit Location Information" : "Add Location Information"}
                        </Typography>
                      </Toolbar>
                      <LocationInformationModal
                        initialData={selectedLocation}
                        onCancel={() => {
                          setLocationInformationOpen(false);
                          setSelectedIndex(-1);
                        }}
                        onAddLocation={(location, index) => {
                          setPrefillData(values);
                          if (index > -1) {
                            locations[index] = location;
                            setLocations([...locations]);
                          } else {
                            setLocations([...locations, location]);
                          }
                          setLocationInformationOpen(false);
                          setSelectedIndex(-1);
                          values = prefillData;
                        }
                        }
                        isEditMode={isEditMode}
                        selectedIndex={selectedIndex}
                        states={states}
                        recordTypes={recordTypes}

                      />

                    </DialogContent>
                  </Dialog>
                  <Dialog
                    open={showDeleteDialog}
                    onClose={() => {
                      setShowDeleteDialog(false);
                      setSelectedIndex(-1);
                    }}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                      DELETE LOCATION
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure, you want this Location Information to be deleted?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={() => {
                          locations.splice(selectedIndex, 1);
                          setLocations([...locations]);
                          setShowDeleteDialog(false);
                          setSelectedIndex(-1);
                        }
                        }>
                        Yes
                      </Button>
                      <Button onClick={() => {
                        setShowDeleteDialog(false);
                        setSelectedIndex(-1);
                      }
                      } color="primary" autoFocus>
                        No
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              );
            }}

          </Formik>
        </div>
      </div>
      : /* If the case has been submitted, then show the order confirmation message instead of the form. */
      <div className={classes.confirmationMessage}>
        Order {caseId} was submitted successfully.
      </div>
  );
}

export const DisplayFormikState = props =>
  <div style={{ margin: '1rem 0' }}>
    {/*<h3 style={{ fontFamily: 'monospace' }} />*/}
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> ={' '}
      {JSON.stringify(props, null, 2)}
    </pre>
  </div>;

export default withStyles(styles)(OrderForm);