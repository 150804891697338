import React from 'react';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    root: {
        margin: 20,
        padding: 20
    },
    paper: {
        padding: theme.spacing(2)
    },
});

const Error = (props) => {
    const classes = props.classes;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={1} square={true}>
                    Oops. There was an error processing this request. Please try again.
                </Paper>
        </div>
    );
}

export default withStyles(styles)(Error);
