import { UserManager } from 'oidc-client';
import { useNavigate } from 'react-router-dom';
import { CarrierConsts } from '../Utils/Consts';

const settings = window.APP_SETTINGS;

const params = new URLSearchParams(window.location.search);

const oidcClientSettings = {
    authority: settings.IDP_AUTHORITY,
    client_id: 'integrated-frontend',
    response_type: 'id_token token',
    scope: 'openid myo-api',
    redirect_uri: window.location.origin + '/signin-callback.html',
    silent_redirect_uri: window.location.href + '/silent-callback.html',
    acr_values: "idp:" + getCarrierName(),
    automaticSilentRenew: true
}

const userManager = new UserManager(oidcClientSettings);

userManager.events.addSilentRenewError(function () {
    userManager.removeUser();
    const navigate = useNavigate();
    navigate(`/tokenfetcherror${window.location.search}`);
});

export const login = (returnPath) => {
    return userManager.signinRedirect({ state: { returnPath } });
}

export const getUser = () => {
      return userManager.getUser();
}

export function getCarrierName() {
    const carrier = getUrlParamByName(CarrierConsts.CarrierKey);
    if (carrier && carrier.toLowerCase() === CarrierConsts.ErieInsuranceParam.toLowerCase()) {
        return CarrierConsts.ErieInsuranceStorageValue;
    }
    return carrier;
}

export function getCarrier() {
    const carrierName = getCarrierName();
    for (const key of Object.keys(settings)) {
        if (key.length > 11) {
            const keyResult = key.substring(0, key.length - 11);
            if (new RegExp(/CARRIERS_CONFIG_.*_[0-9]_CarrierName/i).test(key)
                && settings[key] === carrierName
                && settings[`${keyResult}IsActive`] === 'true') {
                const keys = Object.keys(settings).filter(x => x.includes(keyResult));
                const carrierConfig = { CarrierConfig: keyResult.split('_')[2] }
                for (const key of keys) {
                    carrierConfig[key.split('_')[4]] = settings[key];
                }
                return carrierConfig;
            }
        }
    }
    return null;
}

export function getUrlParamByName(name) {
    return params.get(name);
}
