import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import React from 'react';
import ClientManagerEmailLink from './ClientManagerEmailLink';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    emailLink: {
        textAlign: 'right',
        color: '#fff'
    }
});


const Navbar = (props) => {
    const { title, subject } = props;
    const classes = props.classes;


    return (
        <AppBar position="static" color="primary">
            <Toolbar>
                <Grid container justifyContent='space-between'>
                    <Grid item sx={{
                        marginTop: 'auto',
                        marginBottom: 'auto'
                    }} >
                        <Typography variant="h5" color="inherit">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography component="div" className={classes.emailLink}>
                            <ClientManagerEmailLink subject={subject} />
                        </Typography>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default withStyles(styles)(Navbar);