import * as React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
export const FormikDatePicker = ({
    form: { setFieldValue, handleBlur, errors, touched },
    field: { name, value },
    label,
    maxDate,
    disabled,
    ...rest
}) => {
    const removeTimeOffset = (date) => {
        //value is  undefined or null or empty
        if (!date) return null;
        //remove offset value
        return date.setHours(0, 0, 0, 0);

    }
    return (
        <DatePicker
            label={label}
            maxDate={maxDate ? maxDate : undefined}
            clearable
            keyboard
            disabled={disabled}
            inputFormat="MM/dd/yyyy" // DateFNs format.
            placeholder="mm/dd/yyyy"
            disableOpenOnEnter
            value={removeTimeOffset(value)}
            onBlur={handleBlur}
            onChange={value => { setFieldValue(name, value) }}
            renderInput={
                (params) => <TextField
                variant="standard"
                id={name}
                name={name}
                className={(errors[name] && touched[name]) ? 'datePickerError': ''}
                helperText={(errors[name] && touched[name]) && errors[name]}
                onBlur={handleBlur}
                {...params}
                
                />
            }
            
        />
    );
}