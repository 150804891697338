import React from 'react';
import { withStyles } from '@mui/styles';

const styles = theme => ({
    root: {
        margin: 20,
        padding: 20
    },
});

const GWSessionExpiredMessage = (props) => {
    const classes = props.classes;
    
    return (
        <div className={classes.root}>
            Your session is timed out please reload the page.
        </div>
    );
}

export default withStyles(styles)(GWSessionExpiredMessage);

